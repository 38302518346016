var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"sourceAdd"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitText":'完成',"label-position":'left'},scopedSlots:_vm._u([{key:"saveBeforeSlot",fn:function(){return [_c('v-button',{attrs:{"text":"取消"}})]},proxy:true}])},'form-panel',_vm.submitConfig,false),[_c('div',{staticClass:"title"},[_vm._v("基本信息")]),_c('el-form-item',{attrs:{"label":"模型名称","rules":[{ required: true, message: '请输入名称', trigger: 'blur' }],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入数据源名称"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1),_c('div',{staticClass:"title"},[_vm._v("数据源输入")]),_c('el-form-item',{staticClass:"item-model",attrs:{"label":"模型数据更新周期","rules":[
            { required: true, message: '请输入连接地址', trigger: 'blur' },
          ],"prop":"loginName"}},[_c('div',{staticClass:"check-panel"},[_vm._l((_vm.sceneIdsList),function(item,index){return _c('v-checkbox',{key:index,attrs:{"index":index,"type":"border","label":item.label},on:{"change":_vm.checkChange},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})}),_c('v-input',{attrs:{"placeholder":"请输入数据源名称"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],2)]),_c('div',{staticClass:"title"},[_vm._v("数据转换")]),_c('el-form-item',{attrs:{"label":"选择数据源","rules":[
            {
              required: true,
              message: '请输入数据库连接配置',
              trigger: 'blur',
            },
          ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1),_c('el-form-item',{attrs:{"label":"数据过滤","rules":[
            {
              required: true,
              message: '请输入用户名',
              trigger: 'blur',
            },
          ],"prop":"loginName"}},[_c('div',{staticClass:"check-panel"},_vm._l((_vm.sceneIdsList),function(item,index){return _c('v-checkbox',{key:index,attrs:{"index":index,"type":"border","label":item.label},on:{"change":function($event){return _vm.goFiltration()}},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})}),1)]),_c('el-form-item',{attrs:{"label":"数据计算","rules":[
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ],"prop":"loginName"}},[_c('div',{staticClass:"check-panel"},_vm._l((_vm.sceneIdsList),function(item,index){return _c('v-checkbox',{key:index,attrs:{"index":index,"type":"border","label":item.label},on:{"change":function($event){return _vm.goComputed()}},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})}),1)])],1),_c('div',[_c('el-dialog',{attrs:{"close-on-click-modal":false,"title":"数据过滤","visible":_vm.dialogFit,"width":"50%"},on:{"update:visible":function($event){_vm.dialogFit=$event}}},[_c('form-panel',{ref:"formPanel",attrs:{"form":_vm.form,"hasHeader":false}},[_c('el-form-item',{attrs:{"label":"模型名称","rules":[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入数据源名称"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1),_c('el-form-item',{attrs:{"label":"条件关系","rules":[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1),_c('el-form-item',{attrs:{"label":"过滤条件","rules":[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ],"prop":"loginName"}},[_c('v-table',{ref:"vTable",staticClass:"item-table",attrs:{"tableData":_vm.form.requestList,"headers":_vm.headers,"isOperateColumn":false}})],1),_c('el-form-item',{attrs:{"label":"指标索引","rules":[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){(_vm.dialogFit = false), (_vm.dialogComputed = true)}}},[_vm._v("下一步")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogFit = false}}},[_vm._v("取消")])],1)],1),_c('el-dialog',{attrs:{"close-on-click-modal":false,"title":"数据计算","visible":_vm.dialogComputed,"width":"50%"},on:{"update:visible":function($event){_vm.dialogComputed=$event}}},[_c('form-panel',{ref:"formPanel",attrs:{"form":_vm.form,"hasHeader":false}},[_c('el-form-item',{attrs:{"label":"算子名称","rules":[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入数据源名称"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1),_c('el-form-item',{attrs:{"label":"条件关系","rules":[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1),_c('el-form-item',{attrs:{"label":"计算条件","rules":[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入数据源名称"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1),_c('el-form-item',{attrs:{"label":"计算后字段","rules":[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){(_vm.dialogComputed = false), (_vm.dialogFit = true)}}},[_vm._v("上一步")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogComputed = false}}},[_vm._v("确 定")])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }